import http from "@/http/http-common";
const resource = "/api/frontend";
class MemberService {
    dataMember() {
        return http.get(`${resource}/pageMember`);
    }
    registrationMember(data) {
        return http.post(`${resource}/registrationMember`, data, { useCredentails: true });
    }
}

export default new MemberService();
