<template>
  <div class="custom-dropdown">
    <div class="custom-dropdown__selected-item" tabIndex="0">
      <span>Loại Hội Viện</span>
    </div>
    <div class="custom-dropdown__list" v-if="data">
      <ul>
        <li class="custom-dropdown-list__option" v-for="(item, index) in data" :key="index" @click="setMember(item.id)">{{item[locale].name}}</li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
/* global $ */
export default {
  props: {
    data: null
  },
  mounted: function () {
    this.$nextTick(function () {
      $(".custom-dropdown__selected-item").on("click", function () {
        $(".custom-dropdown__list").toggleClass(
          "custom-dropdown__list--expanded"
        );
      });

      $(".custom-dropdown-list__option").on("click", function (event) {
        $(".custom-dropdown__selected-item").text(
          event.target.textContent.trim()
        );
        $(".custom-dropdown__list").toggleClass(
          "custom-dropdown__list--expanded"
        );
      });
    });
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {
    setMember(id) {
      this.$emit('setMemberSelect', id)
    }
  }
};
</script>
<style lang="scss" scoped>
$blue-text: #112047;
/* ESSENTIAL */
%dropdown-arrow {
  background-image:  url("../../assets/img/icons/arrow-down-orange.svg");
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -0.5em;
  pointer-events: none;
  background-size: 10px 20px;
  display: inline-block;
  width: 10px;
  height: 20px;
  filter: invert(7%) sepia(77%) saturate(2331%) hue-rotate(214deg) brightness(96%) contrast(92%);
  content: "";
}
// super custom
.custom-dropdown__selected-item::after {
  @extend %dropdown-arrow;
}
.custom-dropdown {
  position: relative;
}

.custom-dropdown__list {
  $z-index-foreground: 200;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: $z-index-foreground;
  color: white;
  background: #fff;
  box-shadow: 0px 0px 7px #00000012;
  &--expanded {
    visibility: visible;
    opacity: 1;
  }
}
.custom-dropdown-list__option {
  color: $blue-text;
  cursor: pointer;
  text-align: left;
  text-transform: uppercase;
  padding: 10px 15px;
  font-size: 15px;

  &:not(:first-child) {
    margin-top: -4px;
  }

  &:hover {
    background-color: #f2f2f2;
  }

  &:active,
  &:focus {
    background-color: darken(#f2f2f2, 15%);
  }

  &[disabled] {
    background-color: lightgrey;
    color: black;
    cursor: not-allowed;
    font-style: italic;
  }
}

.custom-dropdown__selected-item {
  cursor: pointer;
  text-align: left;
  background: unset;
  border-bottom: 1px solid #fb8d1c;
  font-size: 17px;
  margin-bottom: 52px;
  padding: 0 0 5px;
  @media screen and (max-width: 1480px) {
    font-size: 15px;
  }
}
</style>
