<template>
  <div class="member" ref="member">
    <div class="category pc">
      <img src="../assets/img/logo/logo-category.svg" alt="" />
      <a class="before" href="/">Trang chủ</a>
      <span class="tag-mid">/</span>
      <a class="after" href="#">Hội Viên</a>
    </div>
    <div class="member-main" v-if="data?.tieu_de">
      <div class="member-main__title" data-aos="fade-up">
        <h5 v-html="data.tieu_de[locale]?.value"></h5>
        <h1 v-html="data.tieu_de[locale]?.description"></h1>
      </div>
      <div class="member-main__content">
        <div class="info-member">
          <div class="info-member__content">
            <h3
              data-aos="fade-up"
              v-html="data?.gioi_thieu[locale]?.value"
            ></h3>
          </div>
          <div class="info-member__bg" data-aos="fade-up">
            <img :src="data?.gioi_thieu?.image" alt="" />
          </div>
        </div>
        <div class="type-member" ref="typemember">
          <div
            class="type-member__vga"
            data-aos="fade-up"
            v-if="data?.hiep_hoi_golf_viet_nam_vga"
          >
            <div class="item">
              <div class="item__bg">
                <img
                  :src="data.hiep_hoi_golf_viet_nam_vga?.background"
                  alt=""
                />
              </div>
              <div class="item__content">
                <a
                  :href="data.hiep_hoi_golf_viet_nam_vga?.path"
                  target="_blank"
                >
                  <div class="icon">
                    <img :src="data.hiep_hoi_golf_viet_nam_vga?.icon" alt="" />
                  </div>
                  <h5
                    class="title"
                    v-html="data.hiep_hoi_golf_viet_nam_vga[locale]?.value"
                  ></h5>
                  <div class="view">
                    <p
                      v-html="
                        data?.hiep_hoi_golf_viet_nam_vga[locale]?.description
                      "
                    ></p>
                    <div class="view__button">
                      <a
                        :href="data.hiep_hoi_golf_viet_nam_vga?.path"
                        target="_blank"
                      >
                        <img
                          src="../assets/img/icons/arrow-right-white.svg"
                          alt=""
                        />
                        <span class="text-uppercase">{{
                          trans("frontend.button.detail")
                        }}</span>
                      </a>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div
            class="type-member__price"
            data-aos="fade-up"
            v-if="data?.bang_phi_hoi_vien"
          >
            <div class="item">
              <div class="item__bg">
                <img :src="data.bang_phi_hoi_vien?.background" alt="" />
              </div>
              <div
                class="item__content"
                @click="activePopup(data.bang_phi_hoi_vien?.price)"
              >
                <div class="icon">
                  <img :src="data.bang_phi_hoi_vien?.icon" alt="" />
                </div>
                <h5
                  class="title"
                  v-html="data.bang_phi_hoi_vien[locale]?.value"
                ></h5>
                <div class="view">
                  <p v-html="data.bang_phi_hoi_vien[locale]?.description"></p>
                  <div
                    class="view__button"
                    @click="activePopup(data.bang_phi_hoi_vien?.price)"
                  >
                    <a :href="data.bang_phi_hoi_vien?.path" target="_blank">
                      <img
                        src="../assets/img/icons/arrow-right-white.svg"
                        alt=""
                      />
                      <span class="text-uppercase">{{
                        trans("frontend.button.detail")
                      }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="benefit-member" v-if="data?.quyen_loi_hoi_vien">
          <h2
            class="benefit-member__title"
            data-aos="fade-up"
            v-html="data.quyen_loi_hoi_vien[locale]?.value"
          ></h2>
          <h5
            class="benefit-member__des"
            ref="benefitmember"
            data-aos="fade-up"
            v-html="data.quyen_loi_hoi_vien[locale]?.description"
          ></h5>
          <div class="benefit-member__content">
            <div class="tab-pane" v-if="dataMember && dataMember.length > 0">
              <div class="tab-pane__menu" data-aos="fade-up">
                <ul id="tab-menu-member">
                  <li
                    v-for="(item, index) in dataMember"
                    :key="index"
                    :class="idMember === item.id ? 'active' : ''"
                    @click="setMember(item.id)"
                  >
                    {{ item[locale]?.name }}
                  </li>
                </ul>
              </div>
              <div class="tab-pane__content">
                <h3
                  class="title"
                  data-aos="fade-up"
                  v-html="detailMember[locale]?.title"
                ></h3>
                <div class="intro">
                  <div class="intro__left" data-aos="fade-up">
                    <img :src="detailMember?.image" alt="" />
                  </div>
                  <div class="intro__right" data-aos="fade-up">
                    <div
                      class="list-info"
                      v-if="
                        detailMember?.benefits &&
                        detailMember.benefits.length > 0
                      "
                    >
                      <div
                        class="item"
                        data-aos="fade-up"
                        v-for="(item, index) in detailMember.benefits"
                        :key="index"
                      >
                        <div class="item__icon">
                          <img :src="item?.icon" alt="" />
                        </div>
                        <div class="item__content">
                          <h5 v-html="item[locale]?.title"></h5>
                          <p v-html="item[locale]?.content"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="intro__content w-full"
                  v-html="detailMember[locale].content"
                  v-if="showContent"
                ></div>
                <div class="view-more" data-aos="zoom-in" @click="showMore()">
                  <a
                    href="javascript:void(0)"
                    :class="showContent ? 'rotate-top' : ''"
                  >
                    <img
                      src="../assets/img/icons/arrow-right-orange.svg"
                      alt=""
                    />
                    <span ref="registermember">{{
                      showContent
                        ? trans("frontend.button.compact")
                        : trans("frontend.button.show more")
                    }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="register-member" data-aos="fade-up">
          <h5 class="sub text-uppercase">
            {{ trans("frontend.form.title.registration") }}
          </h5>
          <h1 class="title">
            {{
              trans("frontend.form.title.request_for_membership_registration")
            }}
          </h1>
          <p class="des">
            {{ trans("frontend.form.title.fill_registration") }}
          </p>
          <form @submit.prevent="submitMember" ref="formMember">
            <div class="info">
              <input
                type="text"
                name=""
                id=""
                :placeholder="trans('frontend.form.nick_name')"
                v-model="form.nick_name"
              />
              <input
                type="text"
                name=""
                id=""
                :placeholder="trans('frontend.form.full_name')"
                v-model="form.full_name"
                required
              />
              <input
                type="text"
                name=""
                id=""
                :placeholder="trans('frontend.form.phone')"
                v-model="form.phone"
                required
              />
              <input
                type="text"
                name=""
                id=""
                :placeholder="trans('frontend.form.email')"
                v-model="form.email"
                required
              />
            </div>
            <div class="type">
              <selectCustom
                :data="dataMember"
                @setMemberSelect="setMemberSelect"
              />
            </div>
            <div class="content">
              <textarea
                type="text"
                name=""
                id=""
                :placeholder="trans('frontend.form.content')"
                v-model="form.content"
              />
            </div>
            <div class="submit">
              <div class="agree">
                <div class="check-box">
                  <input type="checkbox" v-model="form.is_apply" />
                  <p :class="error ? 'error' : ''">
                    {{ trans("frontend.i accept the") }}
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      {{ trans("frontend.terms and conditions") }} *</a
                    >
                  </p>
                </div>
                <div class="check-box">
                  <input type="checkbox" v-model="form.is_send" />
                  <p>{{ trans("frontend.form.send_me_newsletter") }}</p>
                </div>
              </div>
              <button type="submit" :disabled="error">
                <img src="../assets/img/icons/arrow-right-white.svg" alt="" />
                <span class="text-uppercase">{{
                  trans("frontend.button.send to")
                }}</span>
              </button>
            </div>
            <p class="note">{{ trans("frontend.form.required") }}</p>
          </form>
        </div>
      </div>
    </div>
    <popupImage :img="img" :attr="'member'" />
  </div>
</template>
<script>
/* global $ */
import selectCustom from "@/components/select/select.vue";
import MemberService from "@/services/MemberService";
import popupImage from "@/components/popup/popupImage.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    selectCustom,
    popupImage,
  },
  data() {
    return {
      category: "Tin Tức",
      title: "Những Câu CHuyện Mới Nhất Trong Làng Golf",
      type: 0,
      img: "",
      data: "",
      dataMember: [],
      detailMember: "",
      idMember: 1,
      showContent: false,
      form: {
        email: "",
        phone: "",
        full_name: "",
        nick_name: "",
        content: "",
        type_member: "",
        is_apply: false,
        is_send: false,
      },
      error: false,
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      $("#tab-menu-member li").click(function (event) {
        $("#tab-menu-member li").removeClass("active");
        event.target.classList.toggle("active");
      });
      this.checkScroll();
    });
    this.getData();
  },
  watch: {
    "$route.path"() {
      this.checkScroll();
    },
    "form.is_apply"() {
      this.error = !this.form.is_apply;
    },
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {
    activePopup(general) {
      this.img = general;
      $("#popupcommonmember").removeClass("hide");
      $("#popupcommonmember").addClass("active");
    },
    showMore() {
      this.showContent = !this.showContent;
    },
    async getData() {
      await MemberService.dataMember()
        .then((resp) => {
          this.data = resp.data.data;
          this.dataMember = this.data?.type_members;
          this.detailMember = resp.data.data.type_members[0];
        })
        .catch(() => {});
    },
    submitMember() {
      if (this.validate()) {
        let formDatas = new FormData();
        for (var key in this.form) {
          formDatas.append(key, this.form[key]);
        }
        MemberService.registrationMember(formDatas)
          .then(() => {
            this.submit = true;
            this.$toast.fire({
              icon: "success",
              title: "Gửi Thành Công",
            });
            this.$refs.formMember.reset();
          })
          .catch(() => {
            this.submit = false;
            this.$toast.fire({
              icon: "error",
              title: "Gửi Thất Bại",
            });
          });
      }
    },
    validate() {
      if (this.form.is_apply) {
        return true;
      } else {
        this.error = true;
      }
    },
    setMember(id) {
      this.idMember = id;
      this.data.type_members.forEach((element) => {
        if (element.id === id) {
          this.detailMember = element;
        }
      });
      this.$forceUpdate();
    },
    setMemberSelect(id) {
      this.form.type_member = id;
    },
    checkScroll() {
      let el = this.$refs.member;
      let point = "center";
      if (this.$route.path === "/member/VGA") {
        el = this.$refs.typemember;
      } else if (this.$route.path === "/member/benefit") {
        el = this.$refs.benefitmember;
      } else if (this.$route.path === "/member/register") {
        el = this.$refs.registermember;
        point = "start";
      } else if (this.$route.path === "/member/cross") {
        el = this.$refs.typemember;
      }
      this.scrollUp(el, point);
    },
    scrollUp(el, point) {
      el.scrollIntoView({
        behavior: "smooth",
        block: point,
      });
    },
  },
};
</script>
